import Flickity from 'flickity';
import select from 'dom-select';
import throttle from 'lodash.throttle';
import 'flickity-imagesloaded';
import 'flickity-fade';

export default (el) => {
  const ui = {
    el,
    tabsList: select('.tabs__tab-list', el),
    tabs: select.all('.tabs__tab', el),
    panels: select('.tabs__panel-list', el)
  };

  // initialize carousel
  const flkty = new Flickity(ui.panels, {
    adaptiveHeight: true,
    autoPlay: false,
    draggable: false,
    fade: true,
    imagesLoaded: true,
    pageDots: false,
    prevNextButtons: false
  });

  const onLoad = () => {
    flkty.select(0);
    ui.tabs[0].classList.add('is-active');
  };

  // set initial state on page load
  onLoad();

  ui.tabs.forEach((tab, index) => {
    tab.addEventListener('click', () => {
      flkty.select(index);
      ui.tabs.forEach((t) => {
        t.classList.remove('is-active');
      });
      tab.classList.add('is-active');
      tab.blur();
    });
  });

  // settle is a Flickity event fired once slide change completes
  flkty.on('settle', (index) => {
    flkty.resize();
  });

  flkty.on('select', (index) => {
    ui.tabs.forEach((t) => {
      t.classList.remove('is-active');
    });

    ui.tabs[index].classList.add('is-active');
  });

  // resize carousel cells on (throttled) window.resize
  window.addEventListener('resize', throttle(() => {
    flkty.resize();
  },
  250,
  {
    trailing: true,
    leading: true
  }));
};
