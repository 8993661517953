// DOM-specific JS should be in this file in the same
// `export default()` style as in the commented line below

// uncomment line 7 below and the line importing this file into
//  modal.js (line 5) to run your script in the browser

// export default(() => console.log('hello from Modal!'));
import A11yDialog from 'a11y-dialog';
import Flickity from 'flickity';
import select from 'dom-select';

export const Modal = (el) => {
  const ui = {
    el,
    closeBtn: select('.modal__close', el),
    carousel: select('.carousel__container', el),
    overlay: select('modal__overlay', el),
    iframe: el.querySelector('iframe'),
    video: el.querySelector('iframe[src*="www.youtube.com"], iframe[src*="player.vimeo.com"], video')
  };

  const { id } = ui.el;

  const stopVideo = function (el) {
    if (ui.video) {
      ui.video.src = ui.video.src.replace('&autoplay=1', '').replace('?autoplay=1', '');
    }
  }

  const startVideo = function (el) {
    if (ui.video) {
      // ui.video.src = ui.video.src + (ui.video.src.indexOf('?') < 0 ? '?' : '&') + 'autoplay=1';
    }
  }

  ui.toggle = document.querySelector(`#opens--${id}`);

  const dialog = new A11yDialog(ui.el);

  const addEvents = () => {
    ui.toggle.addEventListener('click', () => {
      event.preventDefault();
      dialog.show();
      startVideo();
    });

    dialog.on('show', () => {
      ui.closeBtn.blur();
    });

    dialog.on('hide', () => {
      stopVideo();
    });
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default Modal;
