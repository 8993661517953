import image from '@blocks/image/image.init';
import modal from '@components/modal/modal.init';
import navigation from '@components/global-header/partials/navigation/navigation.init';
import navItem from '@components/global-header/partials/nav-item/nav-item.init';
import table from '@blocks/table/table.init';
import tabs from '@components/tabs/tabs.init';

import domReady from './lib/domReady';
import initModule from './lib/initModule';
import addIeBodyClass from './lib/detectIE11';
import loadSvg from './lib/load-svg';

const svgSpritePath = `${window.location.protocol}//${window.location.host}/bp-sprite.svg`;

// Intialize scripts here requiring DOM access.
//
// Any modules imported here should export a function
// that takes a node as its only parameter.
// Import the module then initialize it below inside
// domScripts(), calling initModule() and passing in
// the function and a selector that aligns with the element
// you want to pass into the module.
// initModule() calls the method on each instance
// of the selector passed, so your script can assume
// it is working on a unique DOM node.
//
// example:
// import coolThing from '../ui/blocks/CoolThing/cool-thing.init';
// initModule(coolThing, '.cool-thing');

const domScripts = () => {
  initModule(addIeBodyClass, 'body');
  initModule(image, '.image__lazy-wrapper');
  initModule(navigation, '.navigation');
  initModule(navItem, '.nav-item');
  initModule(table, '.richtext table');
  initModule(tabs, '.tabs');
  initModule(modal, '.modal');
  loadSvg(svgSpritePath);
};

// domReady ensures our scripts fire inside Storybook
// even when navigating component to component,
// calling the passed function on DOMContentLoaded
// and each time the page changes, using MutationObserver
domReady(domScripts);
