import select from 'dom-select';

export default (url) => {
  const spriteId = 'bp-svg-sprite';
  const sprite = select(`#${spriteId}`);

  // footgun check
  if (typeof url !== 'string') {
    throw new Error('URL must be a string');
  }

  // in order to avoid multiple copies in the DOM
  // not returning because this may be refiring for reasons
  if (sprite) {
    sprite.remove();
  }

  // get the sprite
  const ajax = new XMLHttpRequest();
  ajax.open('GET', url, true);
  ajax.send();
  ajax.onload = () => {
    if (!ajax.responseText || ajax.responseText.substr(0, 4) !== '<svg') {
      throw Error('Failed SVG Response');
    }
    if (ajax.status < 200 || ajax.status >= 300) {
      return;
    }
    // make a hidden wrapper for the sprite to live in
    const div = document.createElement('div');
    div.id = spriteId;
    div.style.height = '0';
    div.innerHTML = ajax.responseText;

    // drop it into the page
    document.body.insertBefore(div, document.body.childNodes[0]);
  };
};
