const windowIsLargerThan = (breakpoint) => {
  const { clientWidth } = document.body;

  if (clientWidth >= breakpoint) {
    return true;
  }

  return false;
};

export default windowIsLargerThan;
