import windowIsLargerThan from '@lib/measureViewport';
import select from 'dom-select';

export const closeMenu = new Event('closeMenu'); // eslint-disable-line

const NavItem = (el) => {
  const ui = {
    el,
    menu: el.querySelector('.nav-item__menu'),
    toggle: select('.navigation__toggle', document),
    icon: select('.nav-item__icon', el),
    close: select('.nav-item__close-icon', el),
    backdrop: select('.global-header__backdrop', document),
    navigation: select('.global-header .navigation', document),
    root: select('.global-header', document),
    body: select('body', document),
    innerItems: null
  };

  const breakpoint = 1024;

  const state = {
    desktop: windowIsLargerThan(breakpoint),
    throttle: false,
    navOpen: false,
    menuOpen: false,
    subNavOpen: false
  };

  const addInnerEvents = () => {
    ui.innerItems = select.all('.link', ui.menu);

    ui.innerItems.forEach((item) => {
      item.addEventListener('keydown', (e) => {
        const { keyCode } = e;

        if (keyCode !== 13) {
          e.preventDefault();
        }

        if (keyCode === 38) {
          if (state.menuOpen === true) {
            if (item.previousElementSibling) {
              item.previousElementSibling.focus();
            } else {
              ui.menu.lastElementChild.focus();
            }
          }
        } else if (keyCode === 40) {
          if (state.menuOpen === true) {
            if (item.nextElementSibling) {
              item.nextElementSibling.focus();
            } else {
              ui.menu.firstElementChild.focus();
            }
          }
        } else if (keyCode === 9) {
          if (state.desktop === true) {
            ui.el.dispatchEvent(closeMenu);
            ui.menu.previousElementSibling.focus();
          } else {
            // ui.back.focus();
          }
        }
      });
    });
  };

  const onMenuOpen = (e) => {
    /*
    console.log('opening!');
    if (ui.menu) {
      const openItem = select('.nav-item.is-open', document);

      if (openItem) openItem.dispatchEvent(closeMenu);

      state.menuOpen = true;
      ui.el.classList.add('is-open');
      ui.toggle.setAttribute('aria-expanded', true);
      ui.body.classList.add('lock-body');

      if (windowIsLargerThan(breakpoint) === false) {
      } else {
        ui.root.classList.add('nav-open');
      }

      if (ui.icon !== null) ui.icon.setAttribute('hidden', true);
      if (ui.close !== null) ui.close.removeAttribute('hidden');

      if (e.type === 'keydown') {
        ui.menu.firstElementChild.focus();
      }

      addInnerEvents();
    }
    */
  };

  const onMenuClose = () => {
    /*
    state.menuOpen = false;
    ui.el.classList.remove('is-open');
    ui.navigation.classList.remove('nav-open');
    ui.toggle.setAttribute('aria-expanded', false);
    ui.root.classList.remove('nav-open');
    ui.body.classList.remove('lock-body');
    
    if (ui.close !== null) ui.close.setAttribute('hidden', true);
    if (ui.icon !== null) ui.icon.removeAttribute('hidden');
    
    ui.toggle.focus();
    */
  };

  const handleMenuToggle = (e) => {
    /*
    if (state.menuOpen === false) {
      onMenuOpen(e);
    } else {
      ui.el.dispatchEvent(closeMenu);
    }
    */
  };

  const addEventListeners = () => {
    ui.el.addEventListener('closeMenu', onMenuClose);

    ui.backdrop.addEventListener('click', () => {
      ui.el.dispatchEvent(closeMenu);
    });

    if (ui.toggle !== null) {
      ui.toggle.addEventListener('click', (e) => {
        handleMenuToggle(e);
      });

      ui.toggle.addEventListener('keydown', (e) => {
        const { keyCode } = e;

        /*
          keydown events:
          tab         9   default
          enter       13  toggle menu
          spacebar    32  toggle menu
          up arrow    38  close menu if open
          down arrow  40  open menu if closed, else focus inner child
        */

        if (keyCode !== 9) {
          e.preventDefault();
        }

        if (keyCode === 13) {
          handleMenuToggle(e);
        }

        /* desktop patterns */
        if (state.desktop === true) {
          if (keyCode === 38) {
            if (state.menuOpen === true) {
              ui.el.dispatchEvent(closeMenu);
            }
          } else if (keyCode === 32) {
            handleMenuToggle(e);
          } else if ((keyCode === 40) && state.menuOpen === false) {
            onMenuOpen(e);
          }
        }
      });
    }
  };

  const init = () => {
    addEventListeners();
  };

  init();
};

export default NavItem;
